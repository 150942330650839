import type { DispItem, DispItemOnly, Item, ItemOnly } from "~/types";

export function convertDispItem(item: Item | ItemOnly): DispItemOnly {
  return {
    ...item,
    img_path: getImgPath(item.item_cd, item.cate_cd),
    url: getUrl(item),
  };
}

// ユニットの商品URLを取得
function getUrl(item: Item | ItemOnly): string {
  // 商品詳細ページへリンク
  if (item.original_item_cd) {
    return `https://famitei.mobi/${item.dir_name_s}/item/${item.item_cd
      .toString()
      .padStart(6, "0")}_${item.cate_cd}`;
  }

  // 商品一覧ページへリンクする
  return `https://famitei.mobi/${item.dir_name_s}/list/${item.cate_cd}`;
}

// ユニットの商品画像のパスを取得
function getImgPath(item_cd: number, cate_cd: number): string {
  const a = Math.floor((item_cd - 1) / 1000)
    .toString()
    .padStart(4, "0");
  const b = item_cd.toString().padStart(6, "0");
  return `https://famitei.mobi/mobiImg/${a}/${b}/main_${cate_cd
    .toString()
    .padStart(4, "0")}.jpg`;
}
